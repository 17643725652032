import { CSSProperties, ReactNode } from 'react';
import { FaSkiing } from 'react-icons/fa';
import {
  PiBagBold,
  PiCarSimpleBold,
  PiHouseBold,
  PiPersonSimpleHikeBold,
} from 'react-icons/pi';
import { ETransportType } from '../../common/types';
import { NAVIGATION } from '../../containers/App/constants';
import DE from '../../images/countries/flags/de.png';
import ES from '../../images/countries/flags/es.png';
import FR from '../../images/countries/flags/fr.png';
import UK from '../../images/countries/flags/gb.png';
import IT from '../../images/countries/flags/it.png';
import NL from '../../images/countries/flags/nl.png';
import { colors } from '../../styles/constants';
import { FlagStyle } from './Common';

export enum Language {
  FR = 'fr', // Français
  EN = 'en', // Anglais
  IT = 'it', // Italien
  ES = 'es', // Espagnol
  DE = 'de', // Allemand
  NL = 'nl', // Néerlandais
}

type NavbarItem = {
  id: string;
  staticText?: string;
  icon?: ReactNode | string;
  iconStyle?: CSSProperties;
};

type NavbarLink = NavbarItem & {
  type: 'link';
  href: string;
  isExternal?: boolean;
};

type NavbarButton = NavbarItem & {
  type: 'button';
};

export const LANGUAGES_ITEMS: Array<NavbarButton> = [
  {
    type: 'button',
    id: Language.FR,
    icon: FR,
    iconStyle: FlagStyle,
  },
  {
    type: 'button',
    id: Language.EN,
    icon: UK,
    iconStyle: FlagStyle,
  },
  {
    type: 'button',
    id: Language.IT,
    icon: IT,
    iconStyle: FlagStyle,
  },
  {
    type: 'button',
    id: Language.ES,
    icon: ES,
    iconStyle: FlagStyle,
  },
  {
    type: 'button',
    id: Language.DE,
    icon: DE,
    iconStyle: FlagStyle,
  },
  {
    type: 'button',
    id: Language.NL,
    icon: NL,
    iconStyle: FlagStyle,
  },
];

const CARRIERS_ITEMS: Array<NavbarLink> = [
  {
    type: 'link',
    id: ETransportType.BUS,
    href: `${NAVIGATION.COMPANY}/bus`,
  },
  {
    type: 'link',
    id: ETransportType.TRAIN,
    href: `${NAVIGATION.COMPANY}/train`,
  },
  {
    type: 'link',
    id: ETransportType.CARPOOLING,
    href: `${NAVIGATION.COMPANY}/carpooling`,
  },
];

const TOP_TRIPS_ITEMS: Array<NavbarLink> = [
  {
    type: 'link',
    id: 'paris-lyon',
    staticText: 'Paris - Lyon',
    href: `${NAVIGATION.SEARCH}/paris/lyon`,
  },
  {
    type: 'link',
    id: 'paris-marseille',
    staticText: 'Paris - Marseille',
    href: `${NAVIGATION.SEARCH}/paris/marseille`,
  },
  {
    type: 'link',
    id: 'paris-toulouse',
    staticText: 'Paris - Toulouse',
    href: `${NAVIGATION.SEARCH}/paris/toulouse`,
  },
  {
    type: 'link',
    id: 'lyon-bruxelles',
    staticText: 'Lyon - Bruxelles',
    href: `${NAVIGATION.SEARCH}/lyon/bruxelles`,
  },
  {
    type: 'link',
    id: 'paris-strasbourg',
    staticText: 'Paris - Strasbourg',
    href: `${NAVIGATION.SEARCH}/paris/strasbourg`,
  },
];

const CONTACT_ITEMS: Array<NavbarLink> = [
  {
    type: 'link',
    id: 'contactSupport',
    href: NAVIGATION.SUPPORT,
  },
  {
    type: 'link',
    id: 'cancellationAndRefund',
    href: `${NAVIGATION.FAQ}/politique-annulation-remboursement-billet`,
    // mustReload: false,
  },
  {
    type: 'link',
    id: 'exchangeMyTicket',
    href: `${NAVIGATION.FAQ}/echanger-mes-billets`,
    // mustReload: true,
  },
  {
    type: 'link',
    id: 'resellYourTickets',
    href: `${NAVIGATION.FAQ}/revendre-billets-trains`,
    // mustReload: false,
    // isCloaked: true,
  },
];

const BEST_OFFERS_ITEMS: Array<NavbarLink> = [
  {
    type: 'link',
    isExternal: true,
    id: 'affiliationAccommodations',
    href: NAVIGATION.AFFILIATION_BOOKING_COM,
    icon: (
      <PiHouseBold
        color={colors['secondary-400']}
        style={{ fontSize: '22px', margin: '18px 6px 0 0' }}
      />
    ),
  },
  {
    type: 'link',
    isExternal: true,
    id: 'affiliationCarRental',
    href: NAVIGATION.AFFILIATION_RENTAL_CARS,
    icon: (
      <PiCarSimpleBold
        color={colors['secondary-400']}
        style={{ fontSize: '22px', margin: '18px 6px 0 0' }}
      />
    ),
  },
  {
    type: 'link',
    isExternal: true,
    id: 'affiliationActivities',
    href: NAVIGATION.AFFILIATION_FUNBOOKER,
    icon: (
      <PiPersonSimpleHikeBold
        color={colors['secondary-400']}
        style={{ fontSize: '22px', margin: '18px 6px 0 0' }}
      />
    ),
  },
  {
    type: 'link',
    isExternal: true,
    id: 'affiliationSki',
    href: NAVIGATION.AFFILIATION_SKISET,
    icon: (
      <FaSkiing
        color={colors['secondary-400']}
        style={{ fontSize: '22px', margin: '18px 6px 0 0' }}
      />
    ),
  },
  {
    type: 'link',
    isExternal: true,
    id: 'affiliationBagages',
    href: NAVIGATION.AFFILIATION_NANNYBAG,
    icon: (
      <PiBagBold
        color={colors['secondary-400']}
        style={{ fontSize: '22px', margin: '18px 6px 0 0' }}
      />
    ),
  },
];

export type SecondaryDrawerData = {
  id: string;
  subTitleTranslationId?: string;
  items: Array<NavbarLink | NavbarButton>;
};

export type SecondaryDrawerSections =
  | 'languages'
  | 'carriers'
  | 'topTrips'
  | 'contact'
  | 'bestOffers';

export const SECONDARY_DRAWER_SECTIONS: Record<
  SecondaryDrawerSections,
  SecondaryDrawerData
> = {
  languages: { id: 'languagesSection', items: LANGUAGES_ITEMS },
  carriers: { id: 'carriersSection', items: CARRIERS_ITEMS },
  topTrips: { id: 'topTripsSection', items: TOP_TRIPS_ITEMS },
  contact: { id: 'contactSection', items: CONTACT_ITEMS },
  bestOffers: {
    id: 'bestOffersSection',
    subTitleTranslationId: 'bestOffersSubTitleSection',
    items: BEST_OFFERS_ITEMS,
  },
};
