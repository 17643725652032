import { defineMessages } from 'react-intl';
import { ETransportType } from '../../common/types';
import { EFunnelStep } from '../../contexts/App/types';
import { colors } from '../../styles/constants';
import { Language } from './config';

export const navbarScope = 'app.components.navbar';

export const navbarSecondaryDrawerMessages = {};

export const navbarMessages = {
  languagesSection: {
    id: `${navbarScope}.languagesSection`,
    defaultMessage: 'Langues',
    en: 'Languages',
    it: 'Le lingue',
    es: 'Idiomas',
    de: 'Sprachen',
    nl: 'Talen',
  },
  topTripsSection: {
    id: `${navbarScope}.topTripsSection`,
    defaultMessage: 'Top trajets',
    en: 'Top travels',
    it: 'Percorsi top',
    es: 'Rutas superiores',
    de: 'Top-Fahrten',
    nl: 'Top routes',
  },
  bestOffersSection: {
    id: `${navbarScope}.bestOffersSection`,
    defaultMessage: 'Bon plans',
    en: 'Good deals',
    es: 'Buenos planes',
    it: 'Buoni affari',
    de: 'Gute Angebote',
    nl: 'Goede deals',
  },
  bestOffersSubTitleSection: {
    id: `${navbarScope}.bestOffersSubTitleSection`,
    defaultMessage: `<span style="color:${colors['secondary400']}">-20% à -50%</span> chez nos partenaires, profitez-en !`,
    en: `<span style="color:${colors['secondary400']}">-20% to -50%</span> at our partners, take advantage of it!`,
    es: `<span style="color:${colors['secondary400']}">-20% a -50%</span> en nuestros socios, ¡aprovecha!`,
    it: `<span style="color:${colors['secondary400']}">-20% a -50%</span> dai nostri partner, approfitta!`,
    de: `<span style="color:${colors['secondary400']}">-20% bis -50%</span> bei unseren Partnern, profitieren Sie davon!`,
    nl: `<span style="color:${colors['secondary400']}">-20% tot -50%</span> bij onze partners, profiteer ervan!`,
  },
  carriersSection: {
    id: `${navbarScope}.carriersSection`,
    defaultMessage: 'Transporteurs',
    en: 'Carriers',
    es: 'Transportistas',
    it: 'Trasportatori',
    de: 'Transportanbieter',
    nl: 'Vervoerders',
  },
  contactSection: {
    id: `${navbarScope}.contactSection`,
    defaultMessage: 'Contact',
    en: 'Contact',
    it: 'Contatto',
    es: 'Contacte con',
    de: 'Kontakt',
    nl: 'Contact',
  },
  // contact section :
  contactSupport: {
    id: `${navbarScope}.contactSupport`,
    defaultMessage: 'Service Client',
    en: 'Support',
    it: 'Servizio clienti',
    es: 'Servicio de atención al cliente',
    de: 'Kundenservice',
    nl: 'Klantenservice',
  },
  cancellationAndRefund: {
    id: `${navbarScope}.cancellationAndRefund`,
    defaultMessage: 'Annulation et Remboursement',
    en: 'Cancellation and Refund',
    it: 'Cancellazione e rimborso',
    es: 'Anulación y reembolso',
    de: 'Stornierung und Erstattung',
    nl: 'Annulering en terugbetaling',
  },
  exchangeMyTicket: {
    id: `${navbarScope}.exchangeMyTicket`,
    defaultMessage: 'Échanger mon billet',
    en: 'Exchange my ticket',
    it: 'Scambio di biglietti',
    es: 'Cambiar mi billete',
    de: 'Mein Ticket eintauschen',
    nl: 'Mijn ticket omruilen',
  },
  resellYourTickets: {
    id: `${navbarScope}.resellYourTickets`,
    defaultMessage: 'Revendre ses billets',
    en: 'Resell your tickets',
    it: 'Vendi il tuo biglietto',
    es: 'Vender su billete',
    de: 'Verkaufen Sie Ihr Ticket',
    nl: 'Verkoop uw ticket',
  },

  // languages section :
  [Language.FR]: {
    id: `${navbarScope}.${Language.FR}`,
    defaultMessage: 'Français',
    en: 'Français',
    it: 'Français',
    es: 'Français',
    de: 'Français',
    nl: 'Français',
  },
  [Language.EN]: {
    id: `${navbarScope}.${Language.EN}`,
    defaultMessage: 'English',
    en: 'English',
    it: 'English',
    es: 'English',
    de: 'English',
    nl: 'English',
  },
  [Language.ES]: {
    id: `${navbarScope}.${Language.ES}`,
    defaultMessage: 'Español',
    en: 'Español',
    es: 'Español',
    it: 'Español',
    de: 'Español',
    nl: 'Español',
  },
  [Language.IT]: {
    id: `${navbarScope}.${Language.IT}`,
    defaultMessage: 'Italiano',
    es: 'Italiano',
    en: 'Italiano',
    it: 'Italiano',
    de: 'Italiano',
    nl: 'Italiano',
  },
  [Language.DE]: {
    id: `${navbarScope}.${Language.DE}`,
    defaultMessage: 'Deutsch',
    es: 'Deutsch',
    en: 'Deutsch',
    it: 'Deutsch',
    de: 'Deutsch',
    nl: 'Deutsch',
  },
  [Language.NL]: {
    id: `${navbarScope}.${Language.NL}`,
    defaultMessage: 'Nederlands',
    es: 'Nederlands',
    en: 'Nederlands',
    it: 'Nederlands',
    de: 'Nederlands',
    nl: 'Nederlands',
  },

  // carriers section :

  [ETransportType.BUS]: {
    id: `${navbarScope}.${ETransportType.BUS}`,
    defaultMessage: 'Bus',
    en: 'Bus',
    it: 'Autobus',
    es: 'Autobús',
    de: 'Bus',
    nl: 'Bus',
  },
  [ETransportType.TRAIN]: {
    id: `${navbarScope}.${ETransportType.TRAIN}`,
    defaultMessage: 'Train',
    en: 'Train',
    it: 'Treno',
    es: 'Tren',
    de: 'Zug',
    nl: 'Trein',
  },
  [ETransportType.CARPOOLING]: {
    id: `${navbarScope}.${ETransportType.CARPOOLING}`,
    defaultMessage: 'Covoiturage',
    en: 'Carpooling',
    it: 'Carpooling',
    es: 'Compartir coche',
    de: 'Mitfahrgelegenheiten',
    nl: 'Carpoolen',
  },

  // other
  login: {
    id: `${navbarScope}.login`,
    defaultMessage: 'Se connecter',
    en: 'Login',
    es: 'Iniciar sesión',
    it: 'Accedi',
    de: 'Einloggen',
    nl: 'Inloggen',
  },
  buyAdvantageCard: {
    id: `${navbarScope}.buyAdvantageCard`,
    defaultMessage: 'Acheter une carte avantage',
    en: 'Buy an advantage card',
    es: 'Comprar una tarjeta de ventaja',
    it: 'Acquista una carta vantaggi',
    de: 'Vorteilskarte kaufen',
    nl: 'Koop een voordeelkaart',
  },
  travelMorePayLess: {
    id: `${navbarScope}.travelMorePayLess`,
    defaultMessage: 'Partir plus, payer moins.',
    en: 'Travel more, pay less.',
    es: 'Viaja más, paga menos.',
    it: 'Viaggia di più, paga di meno.',
    de: 'Mehr reisen, weniger bezahlen.',
    nl: 'Meer reizen, minder betalen.',
  },
  welcome: {
    id: `${navbarScope}.welcome`,
    defaultMessage: 'Bienvenue',
    en: 'Welcome',
    es: 'Bienvenido',
    it: 'Benvenuto',
    de: 'Willkommen',
    nl: 'Welkom',
  },
  helpCenter: {
    id: `${navbarScope}.helpCenter`,
    defaultMessage: "Centre d'aide",
    en: 'Help Center',
    es: 'Centro de ayuda',
    it: 'Centro assistenza',
    de: 'Hilfezentrum',
    nl: 'Helpcentrum',
  },
  goodDeal: {
    id: `${navbarScope}.goodDeal`,
    defaultMessage: 'Bon plans',
    en: 'Good deals',
    es: 'Buenos planes',
    it: 'Buoni affari',
    de: 'Gute Angebote',
    nl: 'Goede deals',
  },
  itsNew: {
    id: `${navbarScope}.itsNew`,
    defaultMessage: 'Nouveau !',
    en: 'New!',
    es: '¡Nuevo!',
    it: 'Nuovo!',
    de: 'Neu!',
    nl: 'Nieuw!',
  },
  affiliationAccommodations: {
    id: `${navbarScope}.affiliationAccommodations`,
    defaultMessage: 'Hébergement',
    en: 'Accommodation',
    es: 'Alojamiento',
    it: 'Alloggi',
    de: 'Unterkünfte',
    nl: 'Accommodati',
  },
  affiliationCarRental: {
    id: `${navbarScope}.affiliationCarRental`,
    defaultMessage: 'Location de voiture',
    en: 'Car rental',
    es: 'Alquiler de coches',
    it: 'Noleggio auto',
    de: 'Autovermietung',
    nl: 'Autoverhuur',
  },
  affiliationActivities: {
    id: `${navbarScope}.affiliationActivities`,
    defaultMessage: 'Activités',
    en: 'Activities',
    es: 'Actividades',
    it: 'Attività',
    de: 'Aktivitäten',
    nl: 'Activiteiten',
  },
  affiliationBagages: {
    id: `${navbarScope}.affiliationBagages`,
    defaultMessage: 'Consigne à bagages',
    en: 'Luggage storage',
    es: 'Consiga de equipaje',
    it: 'Deposito bagagli',
    de: 'Gepäckaufbewahrung',
    nl: 'Bagageopslag',
  },
  affiliationSki: {
    id: `${navbarScope}.affiliationSki`,
    defaultMessage: 'Location de ski',
    en: 'Ski rental',
    es: 'Alquiler de esquís',
    it: 'Noleggio sci',
    de: 'Ski-Verleih',
    nl: 'Ski-verhuur',
  },
  customerService: {
    id: `${navbarScope}.customerService`,
    defaultMessage: 'Service client',
    en: 'Customer Service',
    es: 'Atención al cliente',
    it: 'Servizio clienti',
    de: 'Kundendienst',
    nl: 'Klantenservice',
  },
  howItWorks: {
    id: `${navbarScope}.howItWorks`,
    defaultMessage: 'Fonctionnement',
    en: 'How it works',
    es: 'Cómo funciona',
    it: 'Come funziona',
    de: 'Wie es funktioniert',
    nl: 'Hoe het werkt',
  },
  about: {
    id: `${navbarScope}.about`,
    defaultMessage: 'À propos',
    en: 'About',
    es: 'Acerca de',
    it: 'Informazioni',
    de: 'Über',
    nl: 'Over',
  },
  giveFeedback: {
    id: `${navbarScope}.giveFeedback`,
    defaultMessage: 'Donner votre avis',
    en: 'Give feedback',
    es: 'Dejar tu opinión',
    it: 'Lascia un feedback',
    de: 'Feedback geben',
    nl: 'Geef feedback',
  },
  discountCards: {
    id: `${navbarScope}.discountCards`,
    defaultMessage: 'Cartes de réduction',
    en: 'Discount cards',
    es: 'Tarjetas de descuento',
    it: 'Carte sconto',
    de: 'Rabattkarten',
    nl: 'Kortingkaarten',
  },
  yourReservations: {
    id: `${navbarScope}.yourReservations`,
    defaultMessage: 'Vos réservations',
    en: 'Your bookings',
    es: 'Tus reservas',
    it: 'Le tue prenotazioni',
    de: 'Ihre Buchungen',
    nl: 'Jouw reserveringen',
  },

  // Discount cards advantage
  forAllAges: {
    id: `${navbarScope}.forAllAges`,
    defaultMessage: 'Pour les petits et les grands',
    en: 'For all ages',
    es: 'Para pequeños y grandes',
    it: 'Per grandi e piccini',
    de: 'Für Jung und Alt',
    nl: 'Voor jong en oud',
  },
  validOnMaxTickets: {
    id: `${navbarScope}.validOnMaxTickets`,
    defaultMessage: 'Valables sur un max de billets',
    en: 'Valid on a maximum number of tickets',
    es: 'Válido en un máximo de boletos',
    it: 'Valido su un massimo di biglietti',
    de: 'Gültig für eine maximale Anzahl von Tickets',
    nl: 'Geldig op een maximaal aantal tickets',
  },
  upTo30PercentOff: {
    id: `${navbarScope}.upTo30PercentOff`,
    defaultMessage: "Jusqu'à 30% de réduction",
    en: 'Up to 30% off',
    es: 'Hasta un 30% de descuento',
    it: 'Fino al 30% di sconto',
    de: 'Bis zu 30% Rabatt',
    nl: 'Tot 30% korting',
  },

  // Funnel steps:
  [EFunnelStep.RESULTS]: {
    id: `${navbarScope}.${EFunnelStep.RESULTS}`,
    defaultMessage: 'Résultats',
    en: 'Results',
    it: 'Risultati',
    es: 'Resultados',
    nl: 'Resultaten',
  },
  [EFunnelStep.INFO]: {
    id: `${navbarScope}.${EFunnelStep.INFO}`,
    defaultMessage: 'Informations',
    en: 'Information',
    it: 'Informazioni',
    es: 'Información',
    de: 'Ergebnisse',
    nl: 'Informatie',
  },
  [EFunnelStep.PAYMENT]: {
    id: `${navbarScope}.${EFunnelStep.PAYMENT}`,
    defaultMessage: 'Paiement',
    en: 'Payment',
    it: 'Pagamento',
    es: 'Pago',
    de: 'Zahlung',
    nl: 'Betaling',
  },
};

export default defineMessages(navbarMessages);
