import { CSSProperties, Fragment, ReactNode } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import { ChevronIcon } from '../../images/icons/chevron';
import { colors, screens } from '../../styles/constants';
import { Icon18 } from '../Icon';
import { NavbarWrapperStyle } from './Common';
import { BackButton, MenuLink } from './Common';
import { SecondaryDrawerData } from './config';
import messages, { navbarMessages } from './messages';

const animationClassName = 'secondary-drawer';

const DrawerAnimationStyle = css`
  &.${animationClassName}-appear, &.${animationClassName}-enter {
    transform: translate3d(-107%, 0, 0);
  }

  &.${animationClassName}-appear-active, &.${animationClassName}-enter-active {
    transform: translate3d(0, 0, 0);
    transition: transform 250ms cubic-bezier(0.11, 0.88, 0.35, 0.97);
  }

  &.${animationClassName}-exit {
    transform: translate3d(0, 0, 0);
  }

  &.${animationClassName}-exit-active {
    transform: translate3d(-107%, 0, 0);
    transition: transform 250ms cubic-bezier(0.11, 0.88, 0.35, 0.97);
  }

  @media (min-width: ${screens.lg}) {
    &.${animationClassName}-appear, &.${animationClassName}-enter {
      transform: translate3d(0%, -107%, 0);
    }

    &.${animationClassName}-appear-active,
      &.${animationClassName}-enter-active {
      transform: translate3d(0, 0, 0);
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    &.${animationClassName}-exit {
      transform: translate3d(10, 0, 0);
    }

    &.${animationClassName}-exit-active {
      transform: translate3d(0%, -107%, 0);
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }
`;

const SecondaryDrawerContainer = styled.div`
  ${tw`fixed w-full bg-white lg:absolute`}
  inset: 0;

  @media (min-width: ${screens.lg}) {
    z-index: -1;
    inset: unset;
  }

  ${DrawerAnimationStyle}
`;

const SecondaryDrawerTitle = styled.p`
  ${tw`text-white font-kyrial text-p-large leading-p-large lg:font-semibold lg:text-text `}
`;

const SecondaryDrawerLink = styled(MenuLink)`
  ${tw`font-normal py-medium px-0 flex items-center`}
  text-align: start;
  gap: var(--size-small);
`;

const SecondaryDrawerLinks = styled.div`
  ${NavbarWrapperStyle}
  ${tw`flex flex-col lg:flex-row lg:pb-huge`}

  @media (max-width: ${screens.lg}) {
    & > ${SecondaryDrawerLink}:not(:first-child) {
      border-top: 1px solid;
      ${tw`border-grey-300`}
    }
  }

  @media (min-width: ${screens.lg}) {
    gap: var(--size-huge);
  }
`;

const SecondaryDrawerHeader = styled.div`
  ${NavbarWrapperStyle}
  ${tw`pb-medium flex flex-row bg-primary-400 lg:bg-white lg:pb-0 lg:pt-huge`}
  gap: var(--size-medium);

  padding-top: calc(env(safe-area-inset-top) + var(--size-medium));
`;

export type SecondaryDrawerProps = {
  onClose: () => void;
  data: SecondaryDrawerData | null;
  isOpen: boolean;
  onSecondaryButtonClick: (sectionId: string, itemId: string) => void;
};

export const SecondaryDrawer = (props: SecondaryDrawerProps) => {
  return (
    <CSSTransition
      appear
      classNames={animationClassName}
      in={props.isOpen}
      timeout={250}
      unmountOnExit
    >
      <Fragment>
        {props.data && (
          <SecondaryDrawerContainer data-testid="NavbarSecondaryDrawerView">
            <SecondaryDrawerHeader>
              <BackButton onClick={props.onClose}>
                <ChevronIcon
                  color={colors.white}
                  customStyle={css`
                    ${Icon18}
                  `}
                />
              </BackButton>
              <SecondaryDrawerTitle>
                {props.data.subTitleTranslationId ? (
                  <FormattedHTMLMessage
                    {...messages[props.data.subTitleTranslationId]}
                  />
                ) : (
                  <FormattedMessage {...messages[props.data.id]} />
                )}
              </SecondaryDrawerTitle>
            </SecondaryDrawerHeader>

            <SecondaryDrawerLinks>
              {props.data.items.map((item, index) => {
                const text = item.staticText ?? (
                  <FormattedMessage {...navbarMessages[item.id]} />
                );

                if (item.type === 'button') {
                  return (
                    <SecondaryDrawerLink
                      key={index}
                      as={'button'}
                      onClick={() =>
                        props.onSecondaryButtonClick(props.data.id, item.id)
                      }
                    >
                      <DrawerIcon icon={item.icon} style={item.iconStyle} />
                      {text}
                    </SecondaryDrawerLink>
                  );
                }

                if (item.icon) {
                  return (
                    <div style={{ display: 'flex' }}>
                      {item.icon}
                      <SecondaryDrawerLink
                        key={index}
                        as={item.isExternal ? 'a' : undefined}
                        href={item.isExternal ? item.href : undefined}
                        onClick={props.onClose}
                        target={item.isExternal ? '_blank' : undefined}
                        to={!item.isExternal ? item.href : undefined}
                      >
                        {text}
                      </SecondaryDrawerLink>
                    </div>
                  );
                }

                return (
                  <SecondaryDrawerLink
                    key={index}
                    as={item.isExternal ? 'a' : undefined}
                    href={item.isExternal ? item.href : undefined}
                    onClick={props.onClose}
                    target={item.isExternal ? '_blank' : undefined}
                    to={!item.isExternal ? item.href : undefined}
                  >
                    {text}
                  </SecondaryDrawerLink>
                );
              })}
            </SecondaryDrawerLinks>
          </SecondaryDrawerContainer>
        )}
      </Fragment>
    </CSSTransition>
  );
};

type DrawerIconProps = {
  icon?: ReactNode | string;
  style?: CSSProperties;
};

const DrawerIcon = (props: DrawerIconProps) => {
  if (!props.icon) {
    return null;
  }

  if (typeof props.icon === 'string') {
    return <img alt={'Icon'} src={props.icon} style={props.style} />;
  }

  return <Fragment>{props.icon}</Fragment>;
};
